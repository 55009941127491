var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"py-0":""}},[_c('v-form',{ref:"form"},[_c('v-layout',{attrs:{"px-3":"","pt-3":""}},[_c('v-flex',{attrs:{"xs7":"","sm7":"","md3":"","mr-4":""}},[_c('v-text-field',{attrs:{"label":_vm.$t('users.login'),"success-messages":_vm.userExistsInIdentityMessage,"disabled":_vm.editMode,"autofocus":"","loading":_vm.loginLoading,"rules":[
            () => _vm.rules.required(_vm.login, _vm.$t('users.login')),
            _vm.rules.login
          ]},on:{"blur":_vm.checkUserExistsInIdentity,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return $event.target.blur()}},model:{value:(_vm.login),callback:function ($$v) {_vm.login=$$v},expression:"login"}})],1),_c('v-flex',{attrs:{"xs7":"","sm7":"","md3":"","ml-4":""}},[_c('v-text-field',{attrs:{"label":_vm.$t('users.email'),"rules":[
            () => _vm.rules.required(_vm.email, _vm.$t('users.email')),
            _vm.rules.email
          ]},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1)],1),_c('v-layout',{staticClass:"odd",attrs:{"px-3":"","pt-4":"","mb-4":""}},[_c('v-flex',{attrs:{"xs14":"","sm14":"","md3":""}},[_c('v-select',{attrs:{"items":_vm.roles,"item-text":"roleName","item-value":"roleId","label":_vm.$t('users.role'),"rules":[() => _vm.rules.required(_vm.selectedRole, _vm.$t('users.role'))]},on:{"input":_vm.getRoleRestrictions},model:{value:(_vm.selectedRole),callback:function ($$v) {_vm.selectedRole=$$v},expression:"selectedRole"}})],1)],1),_c('v-layout',{attrs:{"px-3":"","pt-3":""}},[_c('v-flex',[_c('RoleRestrictions',{attrs:{"restrictions":_vm.roleRestrictions}})],1)],1),_c('v-layout',[_c('v-spacer'),_c('v-btn',{staticClass:"light-button",attrs:{"to":{ name: 'users' }}},[_vm._v(" "+_vm._s(_vm.$t("global.cancel"))+" ")]),_c('v-btn',{attrs:{"color":"primary","loading":_vm.loading},on:{"click":_vm.onConfirmClick}},[_vm._v(" "+_vm._s(_vm.acceptButtonValue)+" ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }